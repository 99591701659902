
.introduction__container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.introduction__rights-container {
    position: absolute;
    left: 30px;
    bottom: 50px;
    padding: 12px 37px;
    border-radius: 5px;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > h2 {
        color: white;
        font-size: 12px;
        font-weight: 500;
    }

    & > h3 {
        color: white;
        font-size: 10px;
        font-weight: 300;
    }

    @include xxl {

        @include sm-h {
            left: 20px;
            bottom: 20px;
            padding: 10px 30px;


            & > h2 {
                font-size: 10px;
            }
        
            & > h3 {
                font-size: 9px;
            }

        }
    }

    @include md {

        @include sm-h {
            left: 20px;
            bottom: 20px;
            padding: 10px 30px;

            & > h2 {
            font-size: 10px;
            }
    
            & > h3 {
                font-size: 9px;
            }
        }
    }

    @include sm {
        left: 0px;
        right: 0px;
        bottom: 150px;
        width: fit-content;
        margin: auto;
        padding: 10px 30px;

        & > h2 {
            font-size: 10px;
        }
    
        & > h3 {
            font-size: 9px;
        }
    }

}  

@keyframes pulseTitle {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.introduction__info {
    width: 70%;
    text-transform: uppercase;
    z-index: 2;
    color: white;
    animation: pulseTitle 5s ease-in-out infinite;

    & > h1 {
        font-size: 28px;
        font-weight: 700;
        line-height: 46px;
        letter-spacing: 0.7em;
        text-align: center;
    }

    @include lg {
        width: 75%;
        
        & > h1 {
            font-size: 20px;
        }

        @include sm-h {
            & > h1 {
                font-size: 14px;
            }
        }
    }

    @include md {
        width: 80%;
        
        & > h1 {
            font-size: 20px;
        }

        @include sm-h {
            & > h1 {
                font-size: 12px;
            }
        }
    }

    @include sm {
        
        & > h1 {
            font-size: 18px;
        }
    }

    @include xs {
        
        & > h1 {
            font-size: 14px;
        }
    }

}

.introduction__scroll-container {
    position: absolute;
    right: 30px;
    top: 0px;
    bottom: 0px;
    height: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > h2 {
        color: white;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 200;
        line-height: 15px;
        text-transform: uppercase;
        display: block;
    }

    & > h2 > span {
        display: block;
        margin-bottom: 5px; /* Ajusta el espacio entre las letras según sea necesario */
      }

      @include sm {
        right: 20px;

        & > h2 {
            color: white;
            text-align: center;
            font-size: 8px;
            font-style: normal;
            font-weight: 200;
            line-height: 15px;
            text-transform: uppercase;
            display: block;
        }
    }

    @include xs {
        right: 10px;
    }
}

.introduction__scroll-arrow {
    position: relative;
    margin-top: 30px;
    padding-left: 10px;

    @include sm {
        padding-left: 12.5px;
    }
}


@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

.introduction__scroll-arrow span {
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;

    @include sm {
        width: 50px;
        height: 50px;
    }
}

.introduction__scroll-arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.introduction__scroll-arrow span:nth-child(3) {
    animation-delay: -0.4s;
}
